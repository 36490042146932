<template>
	<div class="ztnrbg">


		<div class="index_mv ztnr">
			<leftMeun></leftMeun>
			<div class="right_nr">
				<rightMeun></rightMeun>
				<div class="list">
					<div class="read_articles" v-if="list.length">
						<!-- {{list}} -->
						<!-- <Row type="flex" justify="start" align="top" style='width: 100%;'> -->
						<!-- <i-col :xs="24" :sm="12" :lg="8" class-name="flexcenter"> -->
						<list :msg="li" v-for="(li,index) in list" :key='index' types="zszx" />
						<!-- </i-col> -->
						<!-- </Row> -->
					</div>

					<div v-else class="read_articles">
						探索中
					</div>
					<div class="pages">
						<Page v-if="count>10" :total="count" @on-change='chan' />
					</div>
				</div>
			</div>
            <newRightMeun></newRightMeun>
		</div>
	</div>
</template>

<script>
	import list from '@/components/textList.vue'
	import leftMeun from '@/components/leftMeun.vue'
	import rightMeun from '@/components/rightMeun.vue'
        import newRightMeun from '@/components/newRightMeun.vue'
	export default {
		components: {
			list,
			leftMeun,
			rightMeun,
             newRightMeun
		},
		data() {
			return {

				list: [],
				page: 1,
				count: 0
			}
		},
		watch: {
			$route: 'types'
		},

		methods: {
			chan(e) {
				var that = this
				that.page = e
				that.lists()
			},
			lists() {
				var that = this
				that.$api.news({
					page: that.page,
					row: 10,
					fid: that.$route.params.newsid,
				}).then(res => {
					that.$emit('getLoad', true);
					that.list = res.data
					that.count = res.count
				}).catch((error) => {
					// error
					console.log(error)
				})
			},
			types() {
				var that = this
				that.$api.news({
					page: 1,
					row: 10,
					fid: that.$route.params.newsid,
				}).then(res => {
					that.$emit('getLoad', true);
					that.list = res.data
					that.count = res.count
					// that.list=that.list.concat(res.data)
				}).catch((error) => {
					// error
					console.log(error)
				})
			}
		},
		mounted() {
			this.types()
		}
	}
</script>

<style>

</style>
